<template>
  <base-dialog
    title="Histórico de caja"
    btn_text="Cierre de caja"
    no_icon
    :width="800"
  >
    <v-row>
      <v-spacer />
      <OpenCloseCash
        ref="form"
        :total_teoric="total_teoric"
        @update="
          fetchCashes();
          $emit('update');
        "
      />
    </v-row>
    <template v-slot:outsite_after>
      <base-data-table-cards
        title="Caja"
        :headers="headers"
        :headers_small="headers_small"
        :data="items"
        :totalItems="total"
        @update="fetchCashes"
      >
        <template v-slot:item_actions="item">
          <v-btn icon @click="$refs.form.edit(item)">
            <v-icon small>$edit</v-icon>
          </v-btn>
        </template>
      </base-data-table-cards>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CashList",
  props: { total_teoric: { type: Number, required: true } },
  data() {
    return {
      items: [],
      total: 0,
      headers: [
        {
          text: "Fecha",
          value: "date",
          sortable: false,
          type: "date.dateShort",
        },
        {
          text: "Total Teórico",
          value: "theory",
          sortable: false,
          type: "number.currency",
        },
        {
          text: "Total Real",
          value: "real",
          sortable: false,
          type: "number.currency",
        },
        {
          text: "Descuadre",
          value: "mismatch",
          sortable: false,
          type: "number.currency",
        },
        {
          text: "Siguiente dia",
          value: "next_day",
          sortable: false,
          type: "number.currency",
        },

        {
          text: "Abrir cerrar",
          value: "open",
          sortable: false,
          type: "boolean.icons",
          true: "mdi-check",
          true_color: "success",
          false: "mdi-close",
          false_color: "error",
        },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      headers_small: [
        { text: "Fecha", value: "date" },
        { text: "Fecha", value: "date" },
        { text: "Fecha", value: "date" },
      ],
    };
  },
  components: {
    OpenCloseCash: () => import("./OpenCloseCash"),
  },
  methods: {
    ...mapActions("cash", ["getCashes"]),
    fetchCashes() {
      this.getCashes({}).then((response) => {
        this.items = response.data;
        this.total = response.total;
      });
    },
  },
};
</script>
<style lang="sass">
#cash-close
  td
    padding: 5px
    height: unset
    .colorBarrita
      > .v-input__control
        > .v-input__slot
          height: 18px
          &:before,
          &:after
            height: 1px
            background-color: var(--v-primary-base) !important
            border-color: var(--v-primary-base) !important
          input
            text-align: center
</style>